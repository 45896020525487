export const BASE_API_URL = process.env.REACT_APP_BASE_URL;
// export const API_KEY = process.env.REACT_APP_API_KEY;
export const API_KEY = "12345"; // staging
// export const API_KEY = "fc94735af57f22eb" // prod
export const API_LOGIN_URL = `/users`;

export const FONT_FAMILY_GENERAL = "Noto Sans Display";
export const COLOR_PRIMARY = "#0099CC";
export const COLOR_SECONDARY = "#FFF";
export const COLOR_VARIANT = "#7ABA57";
export const COLOR_GREY = "rgb(128, 128, 128)";
export const COLOR_ERROR = "#ff0033";
export const COLOR_PRIMARY_LIGHT = "#F5FBFC";
export const COLOR_LINK = "rgb(0, 153, 204)";
export const TEXT_COLOR_PRIMARY = "#000";
export const HEADER_HEIGHT = 72;
export const FOOTER_HEIGHT = "28px";
export const SIDENAV_WIDTH = "20%";
export const API_URL = "https://staging.api.hemehealth.app/doctor/v1"; //staging
// export const API_URL = "https://api.hemehealth.app/doctor/v1"; //prod
export const MEDIA_URL = "https://staging.api.hemehealth.app"; // staging media
// export const MEDIA_URL = "https://dev.api.hemehealth.app"; //dev media
// export const MEDIA_URL = "https://api.hemehealth.app"; // prod media

export const SENTRY_DSN ="https://18e2b679f634ec155afbf5483e92396f@o4505918795546624.ingest.us.sentry.io/4508001924349952" //prod

export const COOKIE_EXPIRY_DAYS = 1826;
export const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
// export const API_URL = "https://dev.hemehealth.app/doctor/v1"; //dev
// export const DEV_MEDIA_URL = "https://dev.hemehealth.app/media/v1";

export const API_ENDPOINT = {
  GENERATE_OTP: `${API_URL}/auth/otp/generate`,
  VERIFY_OTP: `${API_URL}/auth/otp/validate`,
  LOGOUT: `${API_URL}/auth/logout`,
  PATIENTS: `${API_URL}/patients`,
  PATIENT: `${API_URL}/patient`,
  UPLOAD_AUDIO: `${API_URL}/audio/upload`,
  START_TRANSCRIPT: `${API_URL}/audio/summary`,
  START_OPENAI_TRANSCRIPT: `${API_URL}/audio/whisper/summary`,
  MEDIA_UPLOAD: `${MEDIA_URL}/media/v1/upload`,
  SEND_RECORDS: `${API_URL}/patient/medical_data/type`,
  SEND_SCANS: `${API_URL}/patient/medical-data/type/scans/transloadit/send`,
  GET_STUDY_LIST: `${API_URL}/report/dicom/store/all`,
  REMINDERS: `${API_URL}/track/reminders`,
  TODO: `${API_URL}/track/reminders-notification`,
  GET_CHART_RECORDS: `${API_URL}/patient/medical-data/type`,
  FORM: `${API_URL}/form`,
  GET_FORMS: `${API_URL}/form/list`,
  GET_FORM_TYPES: `${API_URL}/form/type`,
  GET_FORM_TEMPLATE: `${API_URL}/form/template`,
  ADD_OBSERVATION: `${API_URL}/patient/medical-data/observation`,
  GET_SUPPORTED_COUNTRIES: `${API_URL}/countries`,
  GET_COHORT_PRESETS: `${API_URL}/analytics/presets`,
  GET_COHORT_ANALYSIS: `${API_URL}/analytics/cohort`,
  GET_QUERY_ANALYTICS: `${API_URL}/analytics/query`,
  POST_TRANSCRIPT_SUMMARY: `${API_URL}/transcribe/summary`,
  OBSERVATION_LIST: `${API_URL}/patient/medical_data/observation/types`,
  GET_ACCESS: `${API_URL}/auth/otp/access`,
  GET_RECIPIENT_LIST: `${API_URL}/share/users`,
  GET_ALL_DOCTOR_DETAIL : `${API_URL}/search/doctor/all`,
  POST_REPORT_TO_DOCTOR_LAB : `${API_URL}/share`,
  GET_STUDY_DETAIL: `${API_URL}/list_data/study`,
  UPDATE_STATUS: `${API_URL}/patient/medical-data/status/update`,
  GET_FILE_DATA: `${API_URL}/patient/health-data/file`,
  GET_RECORDS: `${API_URL}/patient/health-data/type`,
  GET_THUMBNAILS: `${API_URL}/patient/health-data/signed-thumbnail`,
  GET_UPLOAD_RECORDS: `${API_URL}/report/`,
  POLL_RECORDS: `${API_URL}/report/uploaded_files`,
  SPLIT_PDF: `${MEDIA_URL}/media/v1/upload/split-pdf`,
  GET_SCANS: `${API_URL}/list_data/study`,
  POST_REPORT_TO_DOCTOR_ANYONE : `${API_URL}/share/anyone`,
  GET_VIEWER_URLS:  `${API_URL}/list_data/viewer`,
  GET_ATTACHED_REPORT: `${API_URL}/list_data/scans`,
  // GET_TELERADIOLOGYDATA: `${API_URL}/internal/servicerequests`
  GET_TELERADIOLOGYDATA: `${API_URL}/servicerequest`,
  FETCH_TELERADIOLOGYDATA: `${API_URL}/servicerequests/doctor`,
  CHANGE_STATUS_ENCOUNTER: `${API_URL}/patient/encounter/close`,
  GET_STATUS_OF_ENCOUNTER: `${API_URL}/patient/care_episode/encounter`,
  CARE_EPISODE: `${API_URL}/care_episode`,
  GET_TEMPLATE: `${API_URL}/template?category`
};

export const SIZE = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1023px",
  laptopL: "1439px",
  desktop: "2560px",
};

export const DEVICE = {
  mobileS: `(max-width: ${SIZE.mobileS})`,
  mobileM: `(max-width: ${SIZE.mobileM})`,
  mobileL: `(max-width: ${SIZE.mobileL})`,
  tablet: `(max-width: ${SIZE.tablet})`,
  laptop: `(max-width: ${SIZE.laptop})`,
  laptopL: `(max-width: ${SIZE.laptopL})`,
  desktop: `(max-width: ${SIZE.desktop})`,
  desktopL: `(max-width: ${SIZE.desktop})`,
};

export const API_FAILURE_MESSAGE = "Something went wrong, please try again.";
export const SOCKET_ADDRESS =
  "wss://api.hemehealth.app/doctor/ws/v1/analytics/";